import { useSteps } from "react-step-builder";

import "./step9.scss";

export default function StepNove(props) {
  const stepsState = useSteps();

  function onFinish() {
    stepsState.next();
  }

  return (
    <div className="centered-container animated">
      <span className="subtitle left">
        {" "}
        Reservamos este espaço para comentários. Fique à vontade para deixar sua
        opinião ou sugestão.
      </span>
      <span className="optional-legend left">Resposta opcional.</span>

      <input
        className="input-feedback"
        placeholder="Responde aqui..."
        onChange={(input) => props.onChange("step9", input.currentTarget.value)}
      />

      <button className="finish-button" onClick={onFinish}>
        Finalizar
      </button>
    </div>
  );
}
