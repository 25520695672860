import { useSteps } from "react-step-builder";

import Rating from "../../components/rating/rating"

export default function StepCinco(props) {
    const stepsState = useSteps();

    function onClickPreviousStep() {
        stepsState.prev()
    }

    function onChangeValue(value) {
        props.onChange('step5', value)
        stepsState.next()
    }

    return (
        <div className="centered-container animated" >

            <span className="step-title center">Perfeito!</span>
            <span className="subtitle left">E que nota você daria para a qualidade dos produtos?</span>

            <Rating value="1" onChange={onChangeValue} />

            <div className="step-form-actions">
                <span onClick={onClickPreviousStep}><img src="img/back_icon.png"></img> Voltar</span>
            </div>
        </div>
    )
}