import { useSteps } from "react-step-builder";

export default function StepSete(props) {
    const stepsState = useSteps();


    function onClickPreviousStep() {
        stepsState.prev()
    }

    function onChangeValue(value) {
        props.onChange('step7', value)
        stepsState.next()
    }

    return (
        <div className="centered-container animated" >
            {!props.isPromotores && <span className="step-title center">Obrigada por responder até aqui!</span>}
            <span className="subtitle left">Poderia informar qual sua frequência de compra em farmácia ou drogaria?</span>

            <div className="step-actions">
                <button onClick={() => {
                    onChangeValue('semanal')
                }}>Semanal</button>

                <button onClick={() => {
                    onChangeValue('quinzenal')
                }}>Quinzenal</button>

                <button onClick={() => {
                    onChangeValue('mensal')
                }}>Mensal</button>

            </div>

            <div className="step-form-actions">
                <span onClick={onClickPreviousStep}><img src="img/back_icon.png"></img> Voltar</span>
            </div>
        </div>
    )
}