import axios from "axios";
import {v4 as uuidV4} from "uuid";

const token = process.env.REACT_APP_TOKEN

export async function postDataFromApi(data) {
    const headers = {
        'X-Token': token,
        'Content-Type': 'application/json'
    }
    //Incluir a rede no objeto
    data['id_rede'] = "MP";
    data['id_sessao'] = uuidV4();

    const dados = await getIpClient();
    data['nr_ip'] = dados.data.ip;

    //console.log(data)
    
    return axios({
        method: 'post',
        url: process.env.REACT_APP_URL_API + 'postPesquisaSatisfacao',
        data: data,
        headers: headers
    }).then((response) => {
        //console.log(response);
    }, (error) => {
        console.log(error);
    });
}

export async function getLinkGoogleApi(data) {
    const display = data['display']
    var retorno = '';

    if(display) {
        const headers = {
            'X-Token': token,
            'Content-Type': 'application/json'
        }
        return axios({
            method: 'get',
            url: process.env.REACT_APP_URL_API + 'getLinkGoogleApi?display=' + display,
            headers: headers
        }).then((response) => {
            if (response.status === 200) {
                return response.data.data[0].de_link_google;
            }
            //console.log(response);
        }, (error) => {
            //console.log(error);
        });
    }else{
        retorno = ''
    }
}

async function getIpClient() {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response
    } catch (error) {
      console.error(error);
    }
  }
  

