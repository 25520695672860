import './rating.scss'

export default function Rating(props) {
    return (
        <div className='rating'>
            <div className='rating-selector'>
                <span className='rating-number' onClick={() => props.onChange('0')}>0</span>
                <span className='rating-number' onClick={() => props.onChange('1')}>1</span>
                <span className='rating-number' onClick={() => props.onChange('2')}>2</span>
                <span className='rating-number' onClick={() => props.onChange('3')}>3</span>
                <span className='rating-number' onClick={() => props.onChange('4')}>4</span>
                <span className='rating-number' onClick={() => props.onChange('5')}>5</span>
                <span className='rating-number' onClick={() => props.onChange('6')}>6</span>
                <span className='rating-number' onClick={() => props.onChange('7')}>7</span>
                <span className='rating-number' onClick={() => props.onChange('8')}>8</span>
                <span className='rating-number' onClick={() => props.onChange('9')}>9</span>
                <span className='rating-number' onClick={() => props.onChange('10')}>10</span>
            </div>
            <div className='rating-legend'>
                <span>Menor nota</span>
                <span>Maior nota</span>
            </div>
        </div>
    )
}