import Backdrop from "../../components/backdrop/backdrop"
import logo from '../../assets/logo_dcm.svg'
import avaliacaoGoogle from '../../assets/avaliacao_google.svg'
import { useEffect } from "react";

export default function StepFim(props) {
    useEffect(()=>{
        props.onFinish()
    },[]);

    const renderLegend = () =>{
        if (props.isPromotores && props.linkGoogle)
        {
            return (
                <div className="container-legend-img">
                    <span className="step-legend">
                        Obrigada por responder até aqui.<br/>Que tal nos avaliar no Google?
                    </span>
                    <div className="logoAvaliarGoogle">
                        <a href={props.linkGoogle} target='_blank'>
                            <img src={avaliacaoGoogle} />
                        </a>
                    </div>
                </div>
                );
        } else {
            return <span className="step-legend">Sua opinião nos ajudará a evoluir prezando sempre pela sua satisfação, saúde e bem-estar.</span>;
        }
    }
    return (
        <div className="centered-container faded">
            <Backdrop />
            <img className="logo" src={logo} />
            {renderLegend()}
        </div>
    )
}