import React, { useState } from 'react';
import { useSteps } from "react-step-builder";

export default function StepOito(props) {
    const stepsState = useSteps();
    const [data, setData] = useState()

    function onClickPreviousStep() {
        stepsState.prev()
    }

    async function onChangeValue(value) {
        await props.onChange('step8', value)
        if(props.isPromotores){
            stepsState.jump(12)
        }else {
            stepsState.next()
        }
    }

    return (
        <div className="centered-container animated" >

            <span className="subtitle left">Você já compra conosco ou essa seria sua primeira compra?</span>

            <div className="step-actions">
                <button onClick={() => {
                    onChangeValue('Sou cliente')
                }}>Sou cliente</button>

                <button onClick={() => {
                    onChangeValue('Nunca Comprei')
                }}>Nunca Comprei</button>
            </div>

            <div className="step-form-actions">
                <span onClick={onClickPreviousStep}><img src="img/back_icon.png"></img> Voltar</span>
            </div>
        </div>
    )
}